<template>
  <section>
    <div id="home">
      <div class="loading-logo"></div>
      <Hero/>
      <About/>
      <!-- <MeetUps/> -->
      <Speakers/>
      <Schedule/>
      <Sponsors/>
      <RedirectRegister/>
      <CoolStuff/>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import About from '@/components/About.vue'
// import MeetUps from '@/components/MeetUps.vue'
import Speakers from '@/components/Speakers.vue'
import Schedule from '@/components/Schedule.vue'
import Sponsors from '@/components/Sponsors.vue'
import CoolStuff from '@/components/CoolStuff.vue'
import RedirectRegister from '@/components/RedirectRegister.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    About,
    // MeetUps,
    Speakers,
    Schedule,
    Sponsors,
    CoolStuff,
    RedirectRegister,
    
  },
}

</script>

<style>
.home{
  margin: 0;
}
 
import Vue from 'vue'
import Vuex from 'vuex'
import { alert } from '@/store/alert.module';
import { speakers } from '@/store/speakers.module';
import { meetups } from '@/store/meetups.module';
import { sponsors } from '@/store/sponsors.module';
import { schedule} from '@/store/schedule.module';
import { users} from '@/store/users.module';
import { supports} from '@/store/supports.module';


Vue.use(Vuex)

 

export default new Vuex.Store({
    namespaced: true,
    modules: {
      alert,
      speakers,
      meetups,
      sponsors,
      schedule,
      supports,
      users
    }
  });

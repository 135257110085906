import { SPONSORS_SERVICE } from "@/services/sponsors.service.js";
//import router from '@/router';

const state = {
    
    listSponsors: null 

};


const actions = {
    list({ dispatch, commit },) {
        commit('listRequest');
        
        SPONSORS_SERVICE.list()
        .then(
            output => {
                commit('listSuccess', output);
            },
            error => {
                commit('listFailure');
            }
            );
        }
    };
    
   
const mutations = {

    listRequest(state) {
        state.listSponsors = null;
    },
    listSuccess(state, output) {
        state.listSponsors = output;
    },
    listFailure(state) {
        state.listSponsors = false;
    },
}; 

export const sponsors = {
    namespaced: true,
    state,
    actions,
    mutations
};
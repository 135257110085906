<template>
   <div id="app">
      <back-to-top bottom="50px" right="20px">
        <button type="button" class="btn btn-success btn-to-top"><span class="fa fa-chevron-up"></span></button>
      </back-to-top>
      <router-view name="header"></router-view>
      <router-view/>
      <router-view name="footer"></router-view>
  </div>
</template>

<script>
export default {
  components: { 
    
  },

} 

</script>

<style>
#app {
  font-family: Px Grotesk, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-to-top {
  width: 50px!important;
  height: 50px!important;
  border-radius: 100%!important;
  font-size: 22px!important;
  line-height: 22px!important;
  background: #00ff78!important;
  color: white!important;
}
.disabled
{
  pointer-events: none;
  opacity: 0.5;
}
.text-center{
  text-align: center;
}
@font-face {
    font-family: 'DTM-Mono';
    src: 
    url('../src/fonts/DTM-Mono.otf')  format('truetype');
}
@font-face {
    font-family: 'DTM-Sans';
    src: 
    url('../src/fonts/DTM-Sans.otf')  format('truetype');
}
/* variables fonts */
:root{
    --title-font: 'DTM-Sans';
    --text-font: 'DTM-Mono';
}
.title-font{
  font-family: 'DTM-Mono';
  }
.text-font{
  font-family: 'DTM-Sans';
}
</style>
<template>
  <section class="redirectRegister mt-5" data-aos="fade-up" data-aos-duration="2000">
      <div class="row p-0 m-0">
          <div class="col-12">
            <img src="../assets/logo.png" data-aos="zoom-in" data-aos-delay="700" alt="logo" id="logo">
          </div>
      </div>
        <div class="container text-font" data-aos="zoom-in" data-aos-delay="700">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-light text-center" v-html="redirectText"/>
                    <br>
                    <h4 class="text-light text-center text-font">You will be able to register soon!</h4>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-4">
                    <hr class="hrregister">
                </div>
                <div class="col-4">
                    <!-- <h3 class="text-light text-center font-weight-bold">{{ new Date().getFullYear() }}</h3> -->
                    <h3 class="text-light text-center font-weight-bold">2024</h3>
                </div>
                <div class="col-4">
                    <hr class="hrregister">
                </div>
            </div>
                <!-- <div class="form-row justify-content-center mt-3">
                    <a href="/register" class="get-started-btn title-font form-rounded">REGISTER NOW</a>
                </div> -->
        </div>
  </section>
</template>

<script>
export default {
    data() {
        return {
            redirectText: "The event is free, you only need to register"
        }
    },

}
</script>

<style scoped>
.redirectRegister{
    background: url("../assets/redirectRegister.jpg");
    height: 30rem;
    background-size: cover;
}

#logo{
    height:10vh ;
    margin-left: 5rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
  
}
.hrregister{
    border:1px solid white !important;
}
.get-started-btn {
    text-decoration: none;
    background: #00ff78;
    color: black;
    border-radius: 10px;
    padding: 5px 70px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 19px;
    display: inline-block;
    font-weight: bold;
}
.get-started-btn:hover {
  background: #00b371;
  color: #fff;
}
@media (max-width: 576px) {
  h2 {
      font-size: 1.5rem;
  }
}
</style>
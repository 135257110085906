<template>
  <section id="header" class="fixed-top ">
    <div>
      <scrollactive active-class="active" :offset="100" :scrollOffset="1" :duration="800">
        <b-navbar toggleable="lg" type="dark" :class="{ change_color: scrollPosition > 0 }">
          <div class="container">
            <b-navbar-brand href="/">
              <img src="../assets/logo.png" alt="Logo" title="Logo" />
            </b-navbar-brand>
            <b-collapse class="nav-menu" id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto">
                <b-nav-form>
                  <router-link class="nav-link scrollactive-item title-font" active-class="active" to="/#hero">Home</router-link>
                </b-nav-form>
                <b-nav-form>
                  <router-link class="nav-link scrollactive-item title-font" active-class="active" to="/#about">About</router-link>
                </b-nav-form>
                <b-nav-form>
                  <router-link class="nav-link scrollactive-item title-font" active-class="active" to="/#speakers">Speakers</router-link>
                </b-nav-form>
                <!-- <b-nav-form>
                  <router-link class="nav-link scrollactive-item title-font" active-class="active" to="/#schedule">Schedule</router-link>
                </b-nav-form> -->

                <!-- UNCOMMENT WHEN THE EVENT IS ACTIVE -->
                  <!-- <b-nav-form>
                    <a class="nav-link title-font" href="/support">Support</a>
                  </b-nav-form> -->
                  <!-- <b-nav-form>
                    <a class="get-started-btn title-font" href="/register">REGISTER NOW</a>
                  </b-nav-form> -->
                    <!-- <a class="workshops-navbar title-font" href="http://workshops.dtech-event.com/">REGISTER FOR WORKSHOPS</a> -->
                <!-- END OF UNCOMMENT WHEN THE EVENT IS ACTIVE -->
              </b-navbar-nav>
            </b-collapse>
            <b-button class="d-lg-none" id="navbar-toggle" v-b-toggle.sidebar-right variant="transparent" size="lg">
              <b-icon icon="list" variant="light"></b-icon>
            </b-button>
            <b-sidebar id="sidebar-right" title="Sidebar" right width="auto" no-header bg-variant="no-light">
              <template #default="{ hide }">
                <SideMenu @close="hide"></SideMenu>
              </template>
            </b-sidebar>
          </div>
        </b-navbar>
      </scrollactive>
    </div>
  </section>
</template>

<script>
import SideMenu from "@/components/SideMenu.vue";

export default {
  components: {
      SideMenu,
  },
  data() {
    return {
      scrollPosition: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style scoped>
.navbar {
  font-size: 14px;
  letter-spacing: 1px;
  transition: all 0.5s;
  max-height: 100px;
  font-weight: 600;
}
.nav-item {
  padding: 0.2rem;
}

.nav-link {
  color: rgba(202, 206, 221, 0.8);
}
a > img {
  max-height: 60px;
  margin-top: 10px;
}

.nav-menu .navbar-nav > li {
  position: relative;
  white-space: nowrap;
  margin: 0 12px;
}

.router-link-active,
.router-link-exact-active {
  color: #fff;
}

.nav-menu a:hover:before,
.nav-menu li:hover a:before,
.nav-menu .active a:before {
  visibility: visible !important;
  width: 100% !important;
}

.mobile-nav-toggle {
  right: 15px;
  top: 18px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.navbar-toggler-icon {
  margin-top: 0.5rem !important;
}
.btn.focus, .btn:focus{
  box-shadow: none!important;
}
@media screen and (max-width: 991px) {
  .navbar-collapse {
    background: rgba(0, 0, 0, 0.9);
    padding: 20px;
    margin-top: 30px;
    border-radius: 15px;
  }
  .get-started-btn {
    margin: 10px 0 0 0 !important;
    padding: 6px 18px !important;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 410px) {
  a > img {
    max-height: 50px;
    margin-top: 10px;
  }
}

/* Get Startet Button */
a {
  text-decoration: none !important;
}
.get-started-btn {
  background: #00ff78;
  color: black;
  border-radius: 50px;
  padding: 10px 25px 9px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
}

.get-started-btn:hover {
  background: #00b371;
  color: #fff;
}

@media (max-width: 767px) {
  .get-started-btn {
    margin: 10px 0 0 0 !important;
    padding: 6px 18px;
  }
}

.change_color {
  transition: all 0.5s;
  background: rgba(0, 0, 0, 0.7);
}
.workshops-navbar {
  background: white;
  color: black;
  border-radius: 50px;
  padding: 10px 25px 9px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
}

.workshops-navbar:hover {
  background: #2c26a6;
  color: #fff;
}

@media (max-width: 767px) {
  .workshops-navbar {
    margin: 10px 0 0 0 !important;
    padding: 6px 18px;
  }
}
</style>

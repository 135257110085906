<template>
  <section id="schedule" class="section-with-bg" data-aos="fade-up" data-aos-duration="2000">
    <Title class="title-font" title-name="Schedule"></Title>
      <div class="container">
        <!-- Buttons -->
        <ul class="nav nav-tabs text-font" role="tablist" data-aos="zoom-in" data-aos-delay="700">
            <li v-for="scheduleDay in Object.keys(scheduleDays).length" :key="scheduleDay" class="nav-item">
              <a class="nav-link" :href="'#day-'+scheduleDay" role="tab" data-toggle="tab" v-bind:class="{ active: scheduleDay == 1 }">Day {{scheduleDay}}</a>
            </li>
        </ul>
        <!-- Schedule -->
        <div class="tab-content row justify-content-center text-font" data-aos="zoom-in" data-aos-delay="700"> 
            <div v-for="(schedules, date, index) in scheduleDays" :key="index" role="tabpanel" class="col-lg-9 tab-pane fade" v-bind:class="{ active: index == 0, show: index == 0 }" :id="'day-'+(index+1)">  
            <div v-for="schedule in schedules" :key="schedule.id" class="row schedule-item">
                <div class="col-md-2">{{ moment(schedule.time).format("H:mm A") }}</div>
                <div class="col-md-10">
                    <div class="speaker">
                    <img :src="imageUrl + schedule.photo" class="img-fluid photo-speaker" alt="photo" />
                    </div>
                    <h4>{{ schedule.name }}<span> {{ schedule.title }} </span></h4>
                    <p>{{ schedule.info }}</p>
                </div>
                </div> 
            </div>
        </div>
      </div>
      <NoResultsPlaceholder class="no-results text-font" v-if="!scheduleListOutput.length"/> 
</section>
</template>

<script>
import Title from './Title'
import { mapState, mapActions } from "vuex";
import moment from "moment";
import NoResultsPlaceholder from '@/components/NoResultsPlaceholder.vue';



export default {
 data() {
    return {
    }
  },
  components: {
    Title,
    NoResultsPlaceholder
  },
  computed: {
    ...mapState({
      scheduleListOutput: state => state.schedule.listSchedule
    }),
    imageUrl(){
      return [process.env.VUE_APP_ROOT];
    },
    scheduleDays: function () {
      var ans = this.scheduleListOutput.reduce(function(prev,curr) 
      {
        var currTime = moment(curr.time).format(moment.HTML5_FMT.DATE);
        if (!prev[currTime]) prev[currTime] = [];
        prev[currTime].push(curr);
        return prev;
      },{});
      return ans;
    },
  },
  methods: {
    ...mapActions("schedule", ["list"]),
  },
  mounted() {
    this.list();
  }
}
</script>

<style scoped>
#schedule {
  padding: 20px 0 30px 0;
}

#schedule .nav-tabs {
  text-align: center;
  margin: auto;
  display: block;
  border-bottom: 0;
  margin-bottom: 30px;
}

#schedule .nav-tabs li {
  display: inline-block;
  margin-bottom: 0;
}

#schedule .nav-tabs a {
  border: none;
  border-radius: 50px;
  font-weight: 600;
  background-color: #18155D;
  color: #fff;
  padding: 10px 100px;
  margin: 1rem;
}
#schedule .nav-tabs a:hover{
  transform: scale(1.05);
}

@media (max-width: 991px) {
  #schedule .nav-tabs a {
    padding: 8px 60px;
  }
}

@media (max-width: 767px) {
  #schedule .nav-tabs a {
    padding: 8px 50px;
  }
}

@media (max-width: 480px) {
  #schedule .nav-tabs a {
    padding: 8px 30px;
  }
}

#schedule .nav-tabs a.active {
  background-color: #00b371;
  color: #fff;
}

#schedule .sub-heading {
  text-align: center;
  font-size: 18px;
  font-style: italic;
  margin: 0 auto 30px auto;
}

@media (min-width: 991px) {
  #schedule .sub-heading {
    width: 75%;
  }
}

#schedule .tab-pane {
  transition: ease-in-out .2s;
}

#schedule .schedule-item {
  border-bottom: 1px solid #18155D;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: background-color ease-in-out 0.3s;
}

#schedule .schedule-item:hover {
  background-color: #fff;
}

#schedule .schedule-item time {
  padding-bottom: 5px;
  display: inline-block;
}

#schedule .schedule-item .speaker {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  float: left;
  margin: 0 10px 10px 0;
}

#schedule .schedule-item .speaker img {
  height: 100%;
  transform: translateX(-50%);
  margin-left: 50%;
  transition: all ease-in-out 0.3s;
}

#schedule .schedule-item h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

#schedule .schedule-item h4 span {
  font-style: italic;
  color: #19328e;
  font-weight: normal;
  font-size: 16px;
}

#schedule .schedule-item p {
  font-style: italic;
  color: #152b79;
  margin-bottom: 0;
}
.photo-speaker{
  object-fit: cover;
}
</style>
import { USERS_SERVICE } from "@/services/users.service.js";



const state = {
    processTask: undefined
}

const actions = {
    register({ dispatch, commit }, user) {
        commit('registerRequest');
        
        USERS_SERVICE.register(user.firstName, user.lastName,user.phoneNumber,user.email)
        .then(
            output => {
                commit('registerSuccess', output);
                dispatch('alert/success', output, { root: true });
            },
            error => {
                commit('registerFailure', error);
                dispatch('alert/error', error, { root: true }); 
            }
            );
            
        } 
    };
    
   
const mutations = {

    registerRequest(state) {
        state.processTask = null
    },
    registerSuccess(state) {
        state.processTask = true;
    },
    registerFailure(state) {
        state.processTask = false;
    }
        
}; 

export const users = {
    namespaced: true,
    state,
    actions,
    mutations
};
/* Imports */
import { API_HELPER } from "@/helpers/api.js";

/* Exports */
// API services that can be invoked on the modules

export const USERS_SERVICE = {
    register,
};


/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Users";



/* Services */
function register(firstName,lastName,phoneNumber,email) 
{
    if(!firstName || !lastName || !phoneNumber || !email)
    {
        return Promise.reject("The parameters are not complete!");
    }
    
    var raw = JSON.stringify({"firstName":firstName, "lastName":lastName,"phoneNumber":phoneNumber, "email":email});

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "register"]);

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw
    };

    return API_HELPER.apiCall(url,null, requestOptions);

}

<template>
<section id="footer">
  <div class="footer-dark">
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-5 item text-center">
            <h3 class="title-font">Previous editions</h3>
            <ul>
                <a target="_blank" href="https://bit.ly/3tYsRsa" class="title-font"><li>DTech 2018</li></a>
                <a target="_blank" href="https://bit.ly/3lIx9Ae" class="title-font"><li>DTech 2019</li></a>
                <a target="_blank" href="https://www.facebook.com/events/teatro-de-vila-real/dtech-2021/4338901642857737/" class="title-font"><li>DTech 2021</li></a>
                <a href="/SummaryOf2022" class="title-font"><li>DTech 2022</li></a>
            </ul>
          </div>
          <div class="col-12 col-md-6 col-lg-5 item text text-center">
            <h3 class="title-font">Organizers</h3>
             <ul>
                <a target="_blank" href="https://outsmartis.com" class="title-font"><li>Outsmartis</li></a>
                <a target="_blank" href="https://whatwhenwhere.pt" class="title-font"><li>What When Where</li></a>
                <a target="_blank" href="https://www.cm-vilareal.pt" class="title-font"><li>CM Vila Real</li></a>
                <a target="_blank" href="https://www.regiadouro.com/" class="title-font"><li>Regia Douro Park</li></a>
                <a target="_blank" href="https://www.utad.pt/" class="title-font"><li>UTAD</li></a>
            </ul>
          </div>
          <div class="col-12 col-lg-2 item social">
            <a target="_blank" href="https://www.facebook.com/dtech.event" class="text-font"><span class="fa fa-facebook"></span></a>
            <a target="_blank" href="https://www.instagram.com/dtech.event/" class="text-font"><span class="fa fa-instagram"></span></a>
          </div>
        </div>
          <p class="copyright title-font text-light">Outsmartis © {{ new Date().getFullYear() }}</p>
      </div>
    </footer>
  </div>
</section>
</template>

<script>
export default {
  
}
</script>

<style scoped>
 .footer-dark {
  padding:50px 0;
  color:#f0f9ff;
   background-color: rgba(0, 0, 0, 0.8);
}

.footer-dark h3 {
  margin-top:0;
  margin-bottom:12px;
  font-weight:bold;
  font-size:16px;
}

.footer-dark ul {
  padding:0;
  list-style:none;
  line-height:1.6;
  font-size:14px;
  margin-bottom:0;
}

.footer-dark ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.6;
}

.footer-dark ul a:hover {
  opacity:0.8;
}

@media (max-width:767px) {
  .footer-dark .item:not(.social) {
    text-align:center;
    padding-bottom:20px;
  }
}

.footer-dark .item.text {
  margin-bottom:36px;
}

@media (max-width:767px) {
  .footer-dark .item.text {
    margin-bottom:0;
  }
}

.footer-dark .item.text p {
  opacity:0.6;
  margin-bottom:0;
}

.footer-dark .item.social {
  text-align:center;
}

@media (max-width:991px) {
  .footer-dark .item.social {
    text-align:center;
    margin-top:20px;
  }
}

.footer-dark .item.social > a {
  font-size:20px;
  width:36px;
  height:36px;
  line-height:36px;
  display:inline-block;
  text-align:center;
  border-radius:50%;
  box-shadow:0 0 0 1px rgba(255,255,255,0.4);
  margin:0 8px;
  color:#fff;
  opacity:0.75;
}

.footer-dark .item.social > a:hover {
  opacity:0.9;
}

.footer-dark .copyright {
  text-align:center;
  padding-top:24px;
  opacity:0.3;
  font-size:13px;
  margin-bottom:0;
}
</style>
<template>
  <section id="about" 
    data-aos="fade-up"
    data-aos-duration="2000">
      <Title 
        class="title-font"
        title-name="About"
      ></Title>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h5 class="text-center call-to-action text-font" v-html="callToAction"/>
            <p class="text-center text-font" v-html="description"/>
            <h4 class="text-center text-font">This Event is <b class="bold-color">FREE</b>, you only need to register.</h4>
            <h5 class="text-center text-font">You will be able to register soon!</h5>
          </div>
        </div>
        <div class="mt-5" style="width: 100%">
          <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Teatro%20Vila%20real+(Dtech)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" title="Main content" name="contents">></iframe>
        </div>
      </div>
  </section>
</template>

<script>
import Title from '@/components/Title.vue'

export default {
    components: {
        Title
    },
    data() {
      return {
        callToAction: 'DTech is an annual technology event, to attract students, companies, and tech enthusiasts in general.',
        // description: 'The will to bring to Vila Real a technology conference that brings together professionals, students and technology enthusiasts gave birth, in 2018, to the first edition of DTech. After 3 editions of growing success, DTech is already a name recognized by students, professionals and technology enthusiasts.<br><br>On October 18 and 19, DTech returns, under the theme of "Road to success", providing students, professionals and companies with the tools to be the best at what they do.The Theater of Vila Real and UTAD open their doors again to students to show the business reality, giving the stage to professionals to exchange experiences and contacts.'
        description: 'The will to bring to Vila Real a technology conference that brings together professionals, students and technology enthusiasts gave birth, in 2018, to the first edition of DTech. After 4 editions of growing success, DTech is already a name recognized by students, professionals and technology enthusiasts.'
      }
    },
}
</script>

<style scoped>
#about {
  padding: 20px 0 30px 0;
}
.call-to-action{
  font-weight: bold;
}
.bold-color{
  color: #1FFB87;
}
</style>
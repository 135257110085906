/* Imports */
import { API_HELPER } from "@/helpers/api.js";

/* Exports */
// API services that can be invoked on the modules
export const SPEAKERS_SERVICE = {
    list,
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Speakers";


/* Services */
function list() 
{
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);
    
    const requestOptions = {
        method: 'GET',
    };

    return API_HELPER.apiCall(url,null, requestOptions);

} 




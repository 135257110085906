<template>
  <section id="speakers" data-aos="fade-up" data-aos-duration="2000">
    <Title class="title-font" title-name="Speakers"></Title>
    <div class="container">
      <div class="row">
        <div class="gallery">
          <div class="gallery__column" >
            <div class="speaker-box" v-for="speaker in speakersListOutput" :key="speaker.id">
              <a :href="speaker.url" target="_blank" class="gallery__link">
                <figure class="gallery__thumb">
                  <img :src="imageUrl + speaker.photo" class="gallery__image">
                  <figcaption class="gallery__caption text-font">
                    <span>{{speaker.name}}</span>
                    <br>
                    <span>{{speaker.info}}</span>
                  </figcaption>
                </figure>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NoResultsPlaceholder class="text-font" v-if="!speakersListOutput.length"/> 
  </section>
</template>

<script>
import Title from "./Title";
import { mapState, mapActions } from "vuex";
import NoResultsPlaceholder from '@/components/NoResultsPlaceholder.vue';



export default {
  components: {
    Title,
    NoResultsPlaceholder
  },
  computed: {
    ...mapState({
      speakersListOutput: state => state.speakers.listSpeakers,
    }),
    imageUrl(){
      return [process.env.VUE_APP_ROOT];
    }
  },
  methods: {
    ...mapActions("speakers", ["list"]),
  },
  

  mounted() {
    this.list();
  },
};
</script>

<style scoped>
#speakers {
  padding: 20px 0 30px 0;
}

.speaker {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.speaker .details {
  background: rgba(24, 21, 93, 0.7);
  position: absolute;
  left: 0;
  bottom: -8px;
  right: 0;
  text-align: center;
  padding-top: 10px;
}

.speaker .details h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
}

.speaker .details p {
  color: #fff;
  font-size: 12px;
  margin-bottom: 10px;
  font-style: italic;
}

.speaker:hover img {
  transform: scale(1.2);
}
.speaker img {
  transition: all 0.4s ease-in-out;
}
.gallery {
  display: flex;
  padding: 2px;
  transition: 0.3s;
}
.gallery:hover .gallery__image {
  filter: grayscale(1);
}
.gallery__column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 0.5rem;
}
.gallery__link {
  overflow: hidden;
}
.gallery__link:hover .gallery__image {
  filter: grayscale(0);
}
.gallery__link:hover .gallery__caption {
  opacity: 1;
}
.gallery__thumb {
  position: relative;
}
.gallery__image {
  display: block;
  width: 350px;
  transition: 0.3s;
  z-index: -1;
}
.speaker-box:hover{
  z-index: 1;
}
.gallery__image:hover {
  transform: scale(1.1);
}
.gallery__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px 15px 15px;
  width: 100%;
  font-size: 16px;
  color: white;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  transition: 0.3s;
}
@media (max-width: 350px) {
  .gallery__image {
  width: 275px;
  }
}
</style>

<template>
<section>
    <Loading v-if="loading"/>
    <div id="app" v-else>
      <back-to-top bottom="50px" right="20px">
        <button type="button" class="btn btn-success btn-to-top"><span class="fa fa-chevron-up"></span></button>
      </back-to-top>
      <template>
        <div class="header mt-3 text-center justify-content-center">
            <h1 class="text-center">Dtech 2022</h1>
            <p class="text-center">Thank you all, see you in the next edition.</p>
            <a class="button-day-1" href="https://youtu.be/so-48NQz358">Day 1</a>
            <a class="button-day-2" href="https://youtu.be/nnXe-nLO4uY">Day 2</a>
        </div>
        <div class="row"> 
          <div class="column">
            <img src="@/assets/fotos/day1/1.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/2.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/3.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/4.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/5.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/6.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/7.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/13.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/14.jpg" loading="lazy" style="width:100%">
          </div>
          <div class="column">
            <img src="@/assets/fotos/day1/8.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/9.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/10.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/11.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/12.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/13.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/14.jpg" loading="lazy" style="width:100%">
            <!-- <img src="@/assets/fotos/day2/8.jpg" style="width:100%"> -->
          </div>
          <div class="column">
            <img src="@/assets/fotos/day1/15.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/16.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/17.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/18.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/19.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/16.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day1/20.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/2.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/3.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/5.jpg" loading="lazy" style="width:100%">
          </div>
          <div class="column mb-5">
            <img src="@/assets/fotos/day2/5.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/6.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/15.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/9.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/10.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/7.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/11.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/12.jpg" loading="lazy" style="width:100%">
            <img src="@/assets/fotos/day2/17.jpg" loading="lazy" style="width:100%">
          </div>
        </div>
      </template>
  </div>
</section>
</template>

<script>
import Loading from '@/components/Loading'
export default {
  components: { 
    Loading
  },
  data() {
    return {
      loading: true
    }
  },
    mounted(){
      this.loaded()
      LoadingEvent.$on('loading', function(loading){
        this.loading = loading
      })
    },
    methods:{
      loaded(){
        setTimeout(() => {
            this.loading = false;
      }, 2000);
      },
    }

} 

</script>

<style>
#app {
  font-family: Px Grotesk, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-to-top {
  width: 50px!important;
  height: 50px!important;
  border-radius: 100%!important;
  font-size: 22px!important;
  line-height: 22px!important;
  background: #009961!important;
  color: white!important;
}
.disabled
{
  pointer-events: none;
  opacity: 0.5;
}

/* eventEnded */ 

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  margin: 0!important;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

.header h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #009961;
}

/* Temporary css */
.button-day-1{
  background: #00ff78;
  color: white;
  border-radius: 50px;
  padding: 10px 25px 9px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
  margin-right: 1rem;
}
.button-day-2{
  background: #18155D;
  color: white;
  border-radius: 50px;
  padding: 10px 25px 9px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
  margin-left: 1rem;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}

</style>
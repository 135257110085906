import { SUPPORTS_SERVICE } from "@/services/supports.service.js";
//import router from '@/router';
const state = { processTask: undefined };

const actions = {
    sendSupport({ dispatch, commit }, supports) {
        dispatch('alert/clear', null, { root: true });
        commit('sendRequest');
        
        SUPPORTS_SERVICE.send(supports)
            .then(
                output => {
                    commit('sendSuccess', output);
                    dispatch('alert/success', output, { root: true });
                },
                error => {
                    commit('sendFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
}
const mutations = {
    /* send */ 
    sendRequest(state) {
        state.processTask = null;
    },
    sendSuccess(state) {
        state.processTask = true;
    },
    sendFailure(state) {
        state.processTask = false;
    },
};

export const supports = {
    namespaced: true,
    state,
    actions,
    mutations
};
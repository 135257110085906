import { SCHEDULE_SERVICE } from "@/services/schedule.service.js";
//import router from '@/router';

const state = {
    
    listSchedule: [] 

};


const actions = {
    list({ dispatch, commit },) {
        commit('listRequest');
        
        SCHEDULE_SERVICE.list()
        .then(
            output => {
                commit('listSuccess', output);
            },
            error => {
                commit('listFailure');
            }
            );
        }
    };
    
   
const mutations = {

    listRequest(state) {
    },
    listSuccess(state, output) {
        state.listSchedule = output;
    },
    listFailure(state) {
        state.listSchedule = [];
    },
}; 

export const schedule = {
    namespaced: true,
    state,
    actions,
    mutations
};
<template>
  <section id="coolSuff" class="coolSuff" data-aos="fade-up" data-aos-duration="2000">
    <Title class="title-font" title-name="Plus, a lot of cool suff"></Title>
    <div class="container d-flex justify-content-center">
      <div class="row">
        <div v-for="coolStuff in coolStuffs" :key="coolStuff.id" class="col-md-6 col-12" data-aos="zoom-in" data-aos-delay="700">
          <div class="icon-box">
            <div class="icon">
              <img :src="coolStuff.img" class="img-fluid" :alt="coolStuff.imgInfo"/>
            </div>
            <h5 class="title-font">{{ coolStuff.name }}</h5>
            <p class="text-font">{{ coolStuff.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Title from "./Title";

export default {
  components: {
    Title,
  },
  data() {
    return {
      coolStuffs: [],
    };
  },

  created() {
    this.coolStuffs = [
      {
        id: 1,
        name: "AWESOME PRESENTATIONS",
        content:
          "We will have national and international speakers. The first names will start to pop up soon!",
        img: require("../assets/coolstuff/stuff-1.jpg"),
        imgInfo: "PRESENTATION",
      },
      {
        id: 2,
        name: "COMPANY FAIR",
        content: "In the venue, there will be multiple company booths. Your opportunity to create connections!",
        img: require("../assets/coolstuff/stuff-2.jpg"),
        imgInfo: "BOOTH",
      },
      {
        id: 3,
        name: "WORKSHOPS",
        content: "On the morning of the 28th, you will be able to attend one of the multiple workshops at UTAD and Regia Douro Park.",
        img: require("../assets/coolstuff/stuff-3.jpg"),
        imgInfo: "BOOTH",
      },
      {
        id: 4,
        name: "MOBILE APPLICATION",
        content: "Watch the event remotely, check the schedule and answer quizes. Powered by Bewitt.",
        img: require("../assets/coolstuff/stuff-4.png"),
        imgInfo: "MOBILE APPLICATION",
      },
      {
        id: 5,
        name: "NETWORKING MOMENTS",
        content: "There will be plenty of networking moments so that you can meet the speakers and other participants.",
        img: require("../assets/coolstuff/stuff-5.jpg"),
        imgInfo: "NETWORKING MOMENTS",
      },
      {
        id: 6,
        name: "WIN PRIZES",
        content: "Win points with your actions on the application and go up the leaderboard. You can then exchange your points for prizes.",
        img: require("../assets/coolstuff/stuff-6.jpg"),
        imgInfo: "GADGETS",
      }
    ];
  },
};
</script>

<style scoped>
#coolSuff {
  padding: 20px 0 30px 0;
}
.coolSuff .icon-box h5 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.coolSuff .icon-box .icon {
  width: 75px;
  margin-bottom: 20px;
}

.coolSuff .icon-box {
  padding: 50px 30px;
  margin-bottom: 20px;
  min-height: 330px;
}
.coolSuff .icon-box .icon img {
  border-radius: 40px;
}
a {
  color: #272727;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #18155d;
  text-decoration: none;
  background-color: transparent;
}
</style>

<template>
  <section v-if="sponsorsListOutput" id="supporters" class="section-with-bg" data-aos="fade-up" data-aos-duration="2000">
    <Title class="title-font" title-name="Sponsors"></Title>
      <div class="container" data-aos="fade-up" v-for="(sponsors, index) in sponsorsInfoPerLevel" :key="index">
        <span v-if="sponsors && sponsors.length" class="title-font title-level">{{getTitleSponsor(index)}}</span>
        <div class="row justify-content-center">
          <div :class="{'col-6': index <= 2, 'col-lg-4 col-md-4 col-xs-6': index > 2}" v-for="sponsor in sponsors" :key="sponsor.id">
            <div class="no-gutters supporters-wrap clearfix text-font" data-aos="zoom-in" data-aos-delay="700">
              <div class="supporter-logo">
                <a :href="sponsor.link"><img :src="imageUrl + sponsor.photo" class="img-style" alt=""/></a>
              </div>
            </div>
          </div>
        </div>
      </div>  
      <NoResultsPlaceholder v-if="!sponsorsListOutput.length"/> 
  </section>
</template>

<script>
import Title from './Title'
import { mapState, mapActions } from "vuex";
import NoResultsPlaceholder from '@/components/NoResultsPlaceholder.vue';

export default {
  components: {
    Title,
    NoResultsPlaceholder
  },
  computed: {
    ...mapState({
      sponsorsListOutput: (state) => state.sponsors.listSponsors,
    }),
    imageUrl(){
      return [process.env.VUE_APP_ROOT];
    },
    sponsorsInfoPerLevel(){
      let result = [];
      this.sponsorsListOutput.forEach(function(sponsor) {
        if(!result[sponsor.level]){
          result[sponsor.level] = [];
        }
        result[sponsor.level].push(sponsor)
      })    
      return result
    }
  },
  methods: {
    ...mapActions("sponsors", ["list"]),
    getTitleSponsor(level){
      let titleLevel = "";
      switch(level){
        case 1: {
          titleLevel = "Organizers";
          break;
        }
        case 2: {
          titleLevel = "Diamond Sponsors";
          break;
        }
        case 3: {
          titleLevel = "Gold Sponsors";
          break;
        }
        case 4: {
          titleLevel = "Silver Sponsors";
          break;
        }
      }
        return titleLevel
    }
  },

  mounted() {
    this.list();
  },
    
}
</script>

<style scoped>
#supporters {
   padding: 20px 0 30px 0;
}

#supporters .supporters-wrap {
  margin-bottom: 30px;
}

#supporters .supporter-logo {
  margin: 1vw;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.5);
}

#supporters .supporter-logo:hover img {
  transform: scale(1.2);
}

#supporters img {
  transition: all 0.4s ease-in-out;
}
.title-level{
  color: #1ffb87;
  display: flex;
  justify-content: center;
  font-size: 25px;
}
.img-style{
  width: 200px;
}

</style>


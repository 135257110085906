import { SPEAKERS_SERVICE} from "@/services/speakers.service.js";
//import router from '@/router';

const state = {
    
    listSpeakers: [] 

};


const actions = {
    list({ dispatch, commit },) {
        commit('listRequest');
        
        SPEAKERS_SERVICE.list()
        .then(
            output => {
                commit('listSuccess', output);
            },
            error => {
                commit('listFailure');
            }
            );
        }
    };
    
   
const mutations = {

    listRequest(state) {
    },
    listSuccess(state, output) {
        state.listSpeakers = output;
    },
    listFailure(state) {
        state.listSpeakers = [];
    },
}; 

export const speakers = {
    namespaced: true,
    state,
    actions,
    mutations
};
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Supports from '../views/Supports.vue'
import Register from '../views/Register.vue'
import SummaryOf2022 from '../views/SummaryOf2022.vue'

//Router layout Header and footer
import AppHeader from "../layout/AppHeader.vue";
import AppFooter from "../layout/AppFooter.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      header: AppHeader,
      default: Home,
      footer: AppFooter
    }
  },
  {
    path: '/support',
    name: 'Support',
    components: {
      header: AppHeader,
      default: Supports,
      footer: AppFooter
    }
  },
  {
    path: '/register',
    name: 'Register',
    components: {
      header: AppHeader,
      default: Register,
      footer: AppFooter
    }
  },
  {
    path: '/summaryOf2022',
    name: 'SummaryOf2022',
    components: {
      header: AppHeader,
      default: SummaryOf2022,
      footer: AppFooter
    }
  },

  { path: '*', redirect: '/' }
  
  ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <section>
    <!-- Menu -->
    <div class="navbar-container">
      <div class="navbar">
        <ul>
          <li class="py-3"><router-link class="nav-link scrollactive-item " to="/#hero">Home</router-link></li>
          <li class="py-3"><router-link class="nav-link scrollactive-item " to="/#about">About</router-link></li>
          <li class="py-3"><router-link class="nav-link scrollactive-item " to="/#speakers">Speakers</router-link></li>
          <li class="py-3"><router-link class="nav-link scrollactive-item " to="/#schedule">Schedule</router-link></li>
          <li class="py-3"><a class="nav-link" href="/support">Support</a></li>
          <li class="py-3"><a class="get-started-btn title-font" href="/register">REGISTER NOW</a></li>   
          <a class="workshops-navbar title-font" href="http://workshops.dtech-event.com/">REGISTER FOR WORKSHOPS</a> 
        </ul>
      </div>
      <div class="pop-up-close">
        <p class="pop-up-close-p"><img id="btn-close" class="w-100 h-25 sizebtn" src="@/assets/close.svg" @click="$emit('close')"/></p>
      </div> 
    </div>
  </section>
</template>

<script>
export default {
  data(){
    return{
      drag: false,
    }
  },
    mounted() {
      document.addEventListener('mousedown', () => this.drag = false);
      document.addEventListener('mousemove', () => this.drag = true);
      document.addEventListener('mouseup', this.handleMouseUp);
  },
  destroyed() {
      document.removeEventListener('mousedown', () => this.drag = false);
      document.removeEventListener('mousemove', () => this.drag = true);
      document.removeEventListener('mouseup', this.handleMouseUp);
  },
  methods: {
    handleMouseUp(event) {
      var toggle = document.getElementById("navbar-toggle");
      if(toggle.classList.contains("not-collapsed") && !this.drag)
      {
        var element = document.getElementById("sidebar-right");
        var isClickInside = element.contains(event.target);
        var isAnchor = event.target.tagName == 'a';
        var isToggle = event.target.classList.contains("sizebtn");

        if (!isToggle && (!isClickInside || (isClickInside && isAnchor))) 
        {
          document.getElementById("btn-close").click();
        }
      }

      this.drag = false;
    },
  },

 };
</script>

<style scoped>
.shape-background
{
  position: absolute;
  left: 0;
  top: -2px;
  height: calc(100vh + 2px);
}
.navbar-container { 
  margin-left: -15px;
  position: relative;
  text-align: left;
  color: white;
}
.pop-up-close-p
{
  position: absolute;
  top: 30px;
  left: 53px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 25px;
}
ul {
  list-style-type: none;
  margin-top: 15vh;
  margin-left: 1vw;
}
li a {
  display: block;
  color:white !important;
  padding: 8px 16px;
  text-decoration: none !important;
  font-size: 1.2rem;
}
/* Change the link color on hover */
li a:hover {
  color: rgb(155, 155, 155)!important;
}
.get-started-btn:hover{
    color: rgb(228, 228, 228)!important;
}
.get-started-btn {
  background: #00ff78;
  color: black;
  border-radius: 50px;
  padding: 10px 25px 9px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
}
.workshops-navbar {
  background: white;
  color: black !important;
  border-radius: 50px;
  padding: 10px 25px 9px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
  margin-top: 2rem;
  text-decoration: none;
}

.workshops-navbar:hover {
  background: #2c26a6;
  color: #fff;
}

@media screen and (max-height: 991px), screen and (max-width: 991px) {
  .shape-background
  {
    display: none !important;
  }
  .navbar-container
  {
    background-color: rgba(0,0,0,0.8);
    width: 100vw;
    min-height: 100vh;
  }
}
@media screen and (max-width: 576px){
  li a {
    font-size: 1.0rem;
  }
}
</style>


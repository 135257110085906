<template>
    <section>  
        <div id="register" class="d-flex flex-column justify-content-center">
            <div class="register" >
                <Title class="title-font" title-name="Register Here"></Title>      
            </div>
        </div>
        <div class="container register-form mt-5">
            <div class="form">
                <div class="note">
                    <!-- <h2 class="title-font">Sign Up for {{ new Date().getFullYear() }} Event</h2> -->
                    <h2 class="title-font">Sign Up for 2024 Event</h2>
                </div>
                <form class="form-content" @submit.prevent="submitClicked" novalidate>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group p-3">
                                <label for="firstName" class="title-font">First Name</label>
                                <input type="text"  v-model="user.firstName" class="form-control form-rounded text-font" :class="{ 'is-invalid': submitted && $v.user.firstName.$error }"/>
                                <div v-if="submitted && $v.user.firstName.$error" class="invalid-feedback text-font">
                                    <span v-if="!$v.user.firstName.required">First Name is required</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group p-3">
                                <label for="lastName" class="title-font">Last Name</label>
                                <input type="text"  v-model="user.lastName" class="form-control form-rounded text-font" :class="{ 'is-invalid': submitted && $v.user.lastName.$error }" />
                                <div v-if="submitted && $v.user.lastName.$error" class="invalid-feedback text-font">
                                    <span v-if="!$v.user.lastName.required">Last Name is required</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group p-3">
                                <label for="email" class="title-font">Email</label>
                                <input type="email" v-model="user.email" class="form-control form-rounded text-font" :class="{ 'is-invalid': submitted && $v.user.email.$error }" />
                                <div v-if="submitted && $v.user.email.$error" class="invalid-feedback text-font">
                                    <span v-if="!$v.user.email.required">Email is required</span>
                                    <span v-if="!$v.user.email.email">Email is invalid</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group p-3">
                                <label for="phoneNumber" class="title-font">Phone Number</label>
                                <input type="text" v-model="user.phoneNumber" class="form-control form-rounded text-font" :class="{ 'is-invalid': submitted && $v.user.phoneNumber.$error }" />
                                <div v-if="submitted && $v.user.phoneNumber.$error" class="invalid-feedback text-font">
                                    <span v-if="!$v.user.phoneNumber.required">Phone Number is required</span><br>
                                    <span v-if="!$v.user.phoneNumber.phoneNumber">Phone Number is invalid</span>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="text-center">
                        <div class="form-group w-10" v-bind:class="{disabled: disabledButton}">
                            <button class="get-started-btn title-font mt-4 mb-5">
                                <span v-show="!loading" class="title-font">SIGN UP</span>
                                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-show="loading" class="sr-only text-font">Loading</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required, email, numeric} from "vuelidate/lib/validators";
import Title from '@/components/Title.vue'

export default {
  name: 'register',
  components: {
        Title
  },
  data() {
      return {
           user: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: ''
            },
            submitted: false,
            loading: false,
      }
  },
   validations: {
            user: {
                firstName: { required },
                lastName: { required },
                email: { required, email },
                phoneNumber: { required, numeric}
            }
        },
    methods: {
        ...mapActions('users', ['register']),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        submitClicked () {
            this.clearAlert();
            this.submitted = true;
            this.register(this.user);

            // stop here if form is invalid
            this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
            this.loading = true;    
        },  
        resetForm() {
            this.submitted = false;
            this.user = {};
        } 
    },
    computed: {
        ...mapState('users', ['processTask']),
        disabledButton:function(){
            return this.user.firstName && this.user.lastName && this.user.email && this.user.phoneNumber ? false : true;
        },
    },
    mounted(){
        window.scrollTo(0,0)
    },
    watch:
    {
        processTask: function(val)
        {
            if(val != null) // Loading
            {
                if(val)
                {
                    this.loading = false;
                    this.$fire({
                        title: "Register successfully",
                        text: "We are waiting for you",
                        type: "success"
                    })
                    this.resetForm()
                }
                else
                {
                    this.$fire({
                        title: "Register Error",
                        text: "Please try again!",
                        type: "error"
                    })
                }
            }
        }
    }
}
</script>

<style scoped>
#register {
  width: 100%;
  height: 50vh;
  background: url("../assets/register/register.jpg") top center;
  background-size: cover;
  position: relative;
}
#register:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
>>>.section-header h2 {
    color: white;
}
.form-rounded {
border-radius: 10px;
}
.note {
    margin-bottom: 50px;
    position: relative;
    padding-bottom: 20px;
}
.note h2 {
    font-size: 36px;
    text-align: center;
    color: #18155D;
    font-weight: 700;
}
.get-started-btn {
    text-decoration: none;
    background: #00ff78;
    color: #fff;
    border-radius: 10px;
    padding: 5px 100px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 19px;
    display: inline-block;
    font-weight: bold;
    border: none;
}
.get-started-btn:hover {
  background: #00b371;
  color: #fff;
}
@media (max-width: 350px) {
 .get-started-btn {
    padding: 5px 70px;
  }
}
</style>
<template>
  <section>
    <div id="contact" class="d-flex flex-column justify-content-center">
      <div class="contact" >
        <Title class="title-font" title-name="Support"></Title>      
      </div>
    </div>
    <div class="container mt-5">
      <div class="row">
       <div class="col-12 col-sm-6 border-right text-center">
            <font-awesome-icon icon="map-marker-alt" id="icon" />
            <h5 class="mt-3 font-weight-bold title-font">Address</h5>
            <p class="text-font">Regia-Douro Park - Parque</p>
            <p class="text-font"> de Ciência e Tecnologia</p>
       </div>
       <div class="col-12 col-sm-6 text-center">
            <font-awesome-icon icon="envelope" id="icon"/>
            <h5 class="mt-3 font-weight-bold title-font">Email</h5>
            <p class="text-font">info@dtech-event.com</p>
       </div>
      </div>
    </div>
    <form @submit.prevent="submitClick" novalidate>
      <div class="container" id="contactcontainer">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="name" class="title-font">Name</label>
            <input type="text" v-model="support.name" id="name" name="name" class="form-control form-rounded text-font" :class="{ 'is-invalid': submitted && $v.support.name.$error }" />
            <div v-if="submitted && !$v.support.name.required" class="invalid-feedback title-font">Name is required</div>
          </div>
          <div class="form-group col-md-6">
            <label for="email" class="title-font" >Email</label>
              <input type="email" v-model="support.email" id="email" name="email" class="form-control form-rounded text-font" :class="{ 'is-invalid': submitted && $v.support.email.$error }" />
              <div v-if="submitted && $v.support.email.$error" class="invalid-feedback title-font">
                <span v-if="!$v.support.email.required">Email is required</span>
                <span v-if="!$v.support.email.email">Email is invalid</span>
              </div>
          </div> 
        </div>
        <div class="form-group">
          <label for="subject" class="title-font">Subject</label>
          <input type="text" v-model="support.subject" id="subject" subject="subject" class="form-control form-rounded text-font text-font" :class="{ 'is-invalid': submitted && $v.support.subject.$error }" />
          <div v-if="submitted && !$v.support.subject.required" class="invalid-feedback title-font text-font">Subject is required</div>
          <div v-if="submitted && !$v.support.subject.minLength" class="invalid-feedback title-font text-font">Subject must be at least 3 characters long</div>
        </div>
        <div class="form-group">
          <label for="message" class="title-font">Message</label>
          <textarea type="text" rows="5" v-model="support.message" id="message" name="message" class="form-control form-rounded text-font" :class="{ 'is-invalid': submitted && $v.support.message.$error }"></textarea>
          <div v-if="submitted && !$v.support.message.required" class="invalid-feedback title-font">Message is required</div>
          <div v-if="submitted && !$v.support.message.minLength" class="invalid-feedback title-font">Message must be at least 10 characters long</div>
        </div>
        <div class="form-row justify-content-center" v-bind:class="{disabled: disabledButton}">
          <button class="get-started-btn title-font mt-4 mb-5 form-rounded">
            <span v-show="!loading" class="title-font">SEND</span>
            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-show="loading" class="sr-only text-font">Loading</span>
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required, email, minLength } from "vuelidate/lib/validators";
import Title from '@/components/Title.vue'

export default {
  name: 'support',
  components: {
      Title
  },
 
  data(){
    return {
        support: {
            name: '',
            email: '',
            subject: '',
            message: '',          
        },
        submitted: false,
        loading: false,
    }
  },
  validations: {
            support: {
                name: { required },
                email: { required, email },
                subject: { required, minLength: minLength(3) },
                message: { required, minLength: minLength(10) }
                
            }
        },
     methods: {
        ...mapActions ('supports', ['sendSupport']),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
      submitClick () {
          this.clearAlert();
          this.submitted = true;
          this.sendSupport (this.support);
          
          // stop here if form is invalid
          this.$v.$touch();
            if (this.$v.$invalid) {
              return;
            }
          this.loading = true;
      },  
      resetForm() {
        this.submitted = false;
        this.support = {};
      } 
    },
    computed:{
      ...mapState('supports', ['processTask']),

      disabledButton:function(){
          return this.support.name && this.support.email && this.support.subject && this.support.message ? false : true;
      },
    },
    mounted(){
      window.scrollTo(0,0)
    },
    watch:
    {
      processTask: function(val)
      {
        if(val != null) // Loading
        {
          if(val)
          {
            this.loading = false
            this.$fire({
              title: "Sent successfully",
              text: "Thank you for contact support!",
              type: "success"
            })
            this.resetForm() 
          }
          else
          {
            this.$fire({
                title: "Error",
                text: "Please try again!",
                type: "error"
              })
          }
        }
      }
    }
}

</script>

<style scoped>
#contact {
  width: 100%;
  height: 50vh;
  background: url("../assets/contact/contact.jpg") top center;
  background-size: cover;
  position: relative;
}
#contact:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
>>>.section-header h2 {
  color: white;
}
#icon{
  font-size:50px;
  color: #00ff78;
}
.border-right{
  border-right: 3px solid #dee2e6!important;
}
#contactcontainer{
  margin-top: 3rem;
}
.form-rounded {
border-radius: 10px;
}
.get-started-btn {
    text-decoration: none;
    background: #00ff78;
    color: #fff;
    border-radius: 10px;
    padding: 5px 100px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 19px;
    display: inline-block;
    font-weight: bold;
    border: none;
}
.get-started-btn:hover {
  background: #00b371;
  color: #fff;
}
@media (max-width: 576px) {
  .border-right{
    display: unset;

  }
}
</style>
import { render, staticRenderFns } from "./NoResultsPlaceholder.vue?vue&type=template&id=647aa242&scoped=true&"
var script = {}
import style0 from "./NoResultsPlaceholder.vue?vue&type=style&index=0&id=647aa242&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647aa242",
  null
  
)

export default component.exports
import Vue from 'vue'
import router from './router'
import AOS from 'aos'
import VueScrollactive from 'vue-scrollactive';
import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import BackToTop from 'vue-backtotop'
import "font-awesome/css/font-awesome.min.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMapMarkerAlt,faEnvelope  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import store from './store/store'
import moment from 'moment'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import VueSimpleAlert from "vue-simple-alert";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueSimpleAlert);
Vue.use(Vuelidate)




library.add(faMapMarkerAlt,faEnvelope)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.component('VueFontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default);

Vue.prototype.moment = moment


Vue.use(BackToTop)
Vue.config.productionTip = false
Vue.use(VueScrollactive);

var app = new Vue({
  router,
  store,
  created () {
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app')



